// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("C:\\apps\\gatsby\\gatsby01\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("C:\\apps\\gatsby\\gatsby01\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("C:\\apps\\gatsby\\gatsby01\\src\\pages\\blog\\index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("C:\\apps\\gatsby\\gatsby01\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-about-js": () => import("C:\\apps\\gatsby\\gatsby01\\src\\pages\\info\\about.js" /* webpackChunkName: "component---src-pages-info-about-js" */),
  "component---src-pages-info-files-info-js": () => import("C:\\apps\\gatsby\\gatsby01\\src\\pages\\info\\files-info.js" /* webpackChunkName: "component---src-pages-info-files-info-js" */),
  "component---src-pages-page-2-js": () => import("C:\\apps\\gatsby\\gatsby01\\src\\pages\\page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\apps\\gatsby\\gatsby01\\.cache\\data.json")

